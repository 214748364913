/*!
 * Drive Marketing Custom Scripts
 */

/**
 * 1. Site variables and loading state
 * 2. Smooth Scrolling for Page Anchors
 * 3. Load Content For Vue
 * 4. Cookie Notice
 * 5. Video Backgrounds
 */

/*******************************************************
 * 1. Site variables and loading state
 * Stores global variables and promises indicating when things are loaded
 *******************************************************/
import initializeApp from './settings';

initializeApp();

/*******************************************************
 * 2. Smooth Scrolling for Page Anchors
 * If there is a page anchor to go to, go there.
 *
 * This does two things:
 * 1. Make it look all pretty.
 * 2. Account properly for the height offset caused by the navbar. Otherwise the navbar obscures the element.
 *******************************************************/
import { startNativeSmoothScroll } from './components/nativeSmoothScroll';

let themeNavbarOffset = 200;
startNativeSmoothScroll({ offset: themeNavbarOffset });

/*******************************************************
 * 3. Load Content For Vue
 *******************************************************/

//Dependencies
import Vue from 'vue';
//In-house parts
import scrollToError from './plugins/scrollToError';

Vue.use(scrollToError);

/*******************************************************
 * 4. Cookie Notice
 *******************************************************/

import Cookies from 'js-cookie';
import moment from 'moment';
import $ from 'jquery';

function loadCookieNotice() {
  const gdpr = document.getElementById('gdpr');
  const agreedToCookiesDate = Cookies.get('agreedToCookiesDate');

  if (
    typeof agreedToCookiesDate === 'undefined' ||
    agreedToCookiesDate.length === 0
  ) {
    gdpr.classList.remove('d-none');
    //gdpr.classList.add('show');
    $('#gdpr').on('hide.bs.toast', function() {
      //just grab this from Vue
      let timestamp = document.getElementById('app').getAttribute('timestamp');
      timestamp = moment
        .unix(parseInt(timestamp))
        .format('YYYY-MM-DD HH:mm:ss');

      Cookies.set('agreedToCookiesDate', timestamp);
      gdpr.classList.add('d-none');
    });
    $('#gdpr').toast('show');
  } else {
    $('#gdpr').toast('hide');
  }
}

loadCookieNotice();

/**
 * 5. Video Backgrounds
 */
import { startVideoBackgrounds } from './components/videoBackground';

document.addEventListener('DOMContentLoaded', function() {
  startVideoBackgrounds();
});
